import { Route, Switch } from 'react-router-dom';

import MachineDetails from './pages/MachineDetails';
import DownloadReport from './pages/DownloadReport';
import ScheduleJobs from './pages/ScheduleJobs';
import EditScheduleJobs from './pages/EditScheduleJobs';
import ExecutedJobs from './pages/ExecutedJobs';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import MainNavigation from './components/layout/MainNavigation';
import MainDivider from './components/layout/MainDivider';
import Footer from './components/layout/Footer';
import "@aws-amplify/ui/styles.css";
import { withAuthenticator } from '@aws-amplify/ui-react'

function App() {
  return (
    <div>
      <MainNavigation /><MainDivider />
      <Switch>
        <Route path='/' exact>
          <MainPage />
        </Route>
        <Route path='/login' exact>
          <LoginPage />
        </Route>
        <Route path='/machine-details' exact>
          <MachineDetails />
        </Route>
        <Route path='/download-report'>
          <DownloadReport />
        </Route>
        <Route path='/edit-scheduled-jobs'>
          <EditScheduleJobs />
        </Route>
        <Route path='/schedule-jobs'>
          <ScheduleJobs />
        </Route>
        <Route path='/executed-jobs'>
          <ExecutedJobs />
        </Route>
      </Switch>
      <Footer />
    </div>
    
    
  );
}

export default withAuthenticator(App)
