import { useState, useEffect } from "react";
import ExecutedActionList from "../components/action/ExecutedActionList";

function ExecutedJobs() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingData, setIsLoadingData] = useState([]);
  // const [lenghtData, setlenghtData] = useState(0);
  //const[alljob, setScheduleJob] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch("https://flow-token-generator-app.azurewebsites.net/api/token", {
      method: "POST",
      headers: {
        "x-api-key":
          "YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY",
        "content-type": "application/json",
      },
      body: '{"teamName":"schenck-process"}',
    })
      .then((response) => {
        console.log("response.status: ", response.status);
        return response.text();
      })
      .then((data) => {
        let token = data;
        fetch("https://gateway-schenck.flow.librestream.com/jobs/v1/?skip=0&limit=1000", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const len =data.length;
            // const a=100
            console.log(len); 
            
            let alljobs = [];
            let i = 0;
            for (const jobId in data) {
              // console.log(len);
              
             // if (data[jobId].metadata.status == "Completed" && data[jobId].metadata.metadata.ScheduledJobId == null)  {
              //if (data[jobId].metadata.userId == "6279f7c6974ef30001d5af12")  {
              //console.log(data[jobId].metadata.userId);
              setTimeout(() => { fetch(
                "https://accounts-schenck.flow.librestream.com/api/team/user/" +
                data[jobId].metadata.userId,
                {
                  method: 'GET',
                  headers: {
                    Authorization: "Bearer " + token,
                    "content-type": "application/json",
                  },
                }
              )
                .then((response) => {
                  console.log(response.status)
                  // eslint-disable-next-line
                  let res = response.status;
                  if(response.status!==200){i=i+1;}
                  else{return response.json();}
                  
                })
                // eslint-disable-next-line
                .then((result) => {
                    if(typeof result !== 'undefined'){
                  // if (data[jobId].metadata.status == "Completed" && data[jobId].metadata.metadata.ScheduledJobId == null)  {
                    console.log(result);
                    const alljob = {
                      ...data[jobId], result
                    };
                    // console.log(alljob);

                    alljobs.push(alljob);
                    setIsLoadingData(alljobs);
                    console.log(alljobs);
                    
                    console.log(alljobs.length); 
                    
                    
                  };
                  
                  i=i+1
                  console.log(len); 
                  console.log(loadingData); 
                  console.log(i); 
                  if (i === len) {
                    setIsLoading(false);
                  };
                  setTimeout(() => {   }, 5000);
                  
                  
                  // FOR LATER
                  // let o = 0;
                  // for (const jobId in alljobs) {
                  //   //console.log(alljobs[jobId].jobId);
                  //   if (alljobs[jobId].metadata.status == "Completed" && alljobs[jobId].metadata.metadata.ScheduledJobId == null ) {
                      
                  //     o = o + 1;
                  //     console.log("TEST"+o);
                  //   };
                  // };
                })}, 1000);
              //}
            };
           
          });
      });
  }, []);
  

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  return (
    <section>
      <br></br>
      <h1>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;All Jobs</h1>
      <ExecutedActionList items={loadingData} />
    </section>
  );
}

export default ExecutedJobs;
