import { useEffect, useState } from 'react';
import EditItem from './EditItem';
import classes from './ActionList.module.css';

function EditList(props){
  const [orderData,setorderData] = useState(props);
  const [quary,setQuary] = useState("");
  const [order,setorder] = useState("ASC");
  // eslint-disable-next-line
  useEffect(()=>{setorderData (props)},[])
  let sorted = {};
  const sorting = (col)=>{
    if (order ==="ASC"){
      const items = [...orderData.items].sort((a,b)=>
      a[col].toLowerCase() > b[col].toLowerCase() ? 1:-1 )
      sorted = {items}
      setorderData(sorted);
      setorder("DSC");
    }
    if (order ==="DSC"){
      console.log(orderData);
      const items = [...orderData.items].sort((a,b)=>
      a[col].toLowerCase() < b[col].toLowerCase() ? 1:-1 )
      sorted = {items}
      setorderData (sorted);
      setorder("ASC");
    }
  }
    return (
    <ul className={classes.list}>
         <li className={classes.item}>
          <input type="text" placeholder='Search...' onChange={(e)=>setQuary(e.target.value.toLowerCase())} className={classes.search}></input>
        
        <div className={classes.content}>
        <table table class="table container">
        <tr>
          <th className={classes.pointer} onClick={()=>sorting("name")}>&#8593;&#8595; Name</th>
          <th className={classes.pointer} onClick={()=>sorting("created")}>&#8593;&#8595;Created</th>
          <th className={classes.pointer} onClick={()=>sorting("state")}>  &#8593;&#8595;  Status</th>
          <th className={classes.pointer} onClick={()=>sorting("description")}>&#8593;&#8595; Description</th>
          <th className={classes.pointer} onClick={()=>sorting("dueByDate")}>&#8593;&#8595; Due Date</th>
          <th className={classes.pointer} onClick={()=>sorting("executioner")}>&#8593;&#8595; Assigned User</th>
          <th>Change To</th>
          {/* <th className={classes.pointer} onClick={()=>sorting("updated")}> &#8593;&#8595; Updated</th> */}
          <th>Update</th>
        </tr>
        {orderData.items.filter((item)=>item.name.toLowerCase().includes(quary)|| item.description.toLowerCase().includes(quary)|| item.result.name.toLowerCase().includes(quary)).map((item)=> (
        <EditItem  jobid={item.tasks[0].jobId} id={item.jobId} name={item.name} description={item.description} created={item.created.split('T')[0]} state={item.state} dueByDate={item.dueByDate} executioner={item.result.name}  updated={item.updated.split('T')[0]}/>
        ))}
         </table>
        </div>
    </li>
    </ul>
    
    );

}

export default EditList;