import { useEffect, useState } from 'react';
import ExecutedActionItem from './ExecutedActionItem';
import classes from './ActionList.module.css';

function ExecutedActionList(props){
  const [orderData,setorderData] = useState(props);
  const [quary,setQuary] = useState("");
  const [order,setorder] = useState("ASC");
    // eslint-disable-next-line
  useEffect(()=>{setorderData (props)},[])
  let sorted = {};
  const sorting = (col)=>{
    if (order ==="ASC"){
      console.log(col);
      const items = [...orderData.items].sort((a,b)=>
      a.col > b.col ? 1:-1 )
      sorted = {items}
      setorderData(sorted);
      setorder("DSC");
    }
    if (order ==="DSC"){
      console.log(orderData);
      const items = [...orderData.items].sort((a,b)=>
      a.col < b.col ? 1:-1 )
      sorted = {items}
      setorderData (sorted);
      setorder("ASC");
    }
  }
    return (
    <ul className={classes.list}>
         <li className={classes.item}>
          <input type="text" placeholder='Search...' onChange={(e)=>setQuary(e.target.value.toLowerCase())} className={classes.search}></input>
        
        <div className={classes.content}>
        <table table class="table container">
        <tr>
          <th className={classes.pointer} onClick={()=>sorting("metadata.jobTitle")}>&#8593;&#8595; Name</th>
          <th className={classes.pointer} onClick={()=>sorting("metadata.modified")}>&#8593;&#8595; Completed On</th>
          <th className={classes.pointer} onClick={()=>sorting("metadata.status")}>  &#8593;&#8595;  Status</th>
          <th className={classes.pointer} onClick={()=>sorting("result.name")}>&#8593;&#8595; Completed By</th>
          {/* <th className={classes.pointer} onClick={()=>sorting("updated")}> &#8593;&#8595; Updated</th> */}
          <th>Download</th>
        </tr>
        {orderData.items.filter((item)=>item.metadata.jobTitle.toLowerCase().includes(quary)|| item.result.name.toLowerCase().includes(quary)).map((item)=> (
        <ExecutedActionItem   jobId={item.jobId} clientJobId={item.metadata.clientJobId} id={item.metadata.workflowId} name={item.metadata.jobTitle}  created={item.metadata.modified.split('T')[0]} state={item.metadata.status} executioner={item.result.name}/>
        ))}
         </table>
        </div>
    </li>
    </ul>
    
    );

}

export default ExecutedActionList;