import { Link } from 'react-router-dom';
import hub from './hub.png';
import logo from './Schenck.png';

import classes from './MainNavigation.module.css';
import { useHistory } from "react-router-dom";

function MainNavigation() {
  const history = useHistory();

  function handleClick() {
    history.push("/");
  }
  return (
    <header className={classes.header}>
     {/* eslint-disable-next-line */}
      <div className={classes.logo} onClick={handleClick}><img className={classes.hub} src={hub}  />  <h3 className={classes.hubtext}>CONiQ Assist Hub</h3></div>
      <nav>
        <ul>
          <li>
            <Link to='/'>Home</Link>
          </li>
          {/* <li>
            <Link to='/login'>Login</Link>
          </li> */}
          <li>
            <Link to='/machine-details'>Machine Details</Link>
          </li>
          <li>
            <Link to='/schedule-jobs'>Schedule Jobs</Link>
          </li>
          <li>
            <Link to='/edit-scheduled-jobs'>Edit Scheduled Jobs</Link>
          </li>
          <li>
            <Link to='/executed-jobs'>All Jobs</Link>
          </li>
          <li>
            <Link to='/download-report'>Download Report</Link>
          </li>
        </ul>
      </nav>
       {/* eslint-disable-next-line */}
      <img className={classes.schenck} src={logo}  onClick={handleClick}/>
    </header>
    
    
  );
}

export default MainNavigation;
