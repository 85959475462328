// import * as XLSX from "xlsx";
import classes from '../components/action/ActionList.module.css';
// eslint-disable-next-line
import React, { useState,useEffect } from "react";
import * as AWS from 'aws-sdk'

// const docClient = new AWS.DynamoDB.DocumentClient()
// export const fetchData = (Client) => {
//   var params = {
//       TableName: Client
//   }

//   docClient.scan(params, function (err, data) {
//       if (!err) {
//           console.log(data)
//       }
//   })
// }

function MachineDetails() {
  
  const [clientdb, setClients] = useState([]);
  const [machinedb, setMachines] = useState([]);
  const [machinedisplaydb, setMachinesdisplaydb] = useState([]);
  const [sitedb, setSites] = useState([]);
  const [fetchtrue, setFetchedtrue] = useState(false);
  const [quary,setQuary] = useState("");
  const docClient = new AWS.DynamoDB.DocumentClient()
  // const FetchData = (Client) => {
  //   // eslint-disable-next-line
  //   var params_client = {
  //       TableName: 'Client'
  //   }
  //   console.log("I am in")
  //   docClient.scan(params_client, function (err, data) {
  //       if (!err) {
  //           const machinetable = {
  //                 ...data.Items
  //           };
  //           setCleints(cleintdb.push(machinetable))
  //           console.log(cleintdb)
  //       }
  //   })
  //   var params_site = {
  //     TableName: 'Site'
  //     }
  //     console.log("I am in")
  //     docClient.scan(params_site, function (err, data) {
  //         if (!err) {
  //           // eslint-disable-next-line
  //             const machinetable = {
  //                   ...data.Items
  //             };
  //             setSites(sitedb.push(...data.Items))
  //             console.log(sitedb)
  //         }
  //     })
  //     var params = {
  //       TableName: 'MachineDetails'
  //   }
  //   console.log("I am in")
  //   docClient.scan(params, function (err, data) {
  //       if (!err) {
  //          // eslint-disable-next-line
  //           const machinetable = {
  //                 ...data.Items
  //           };
  //           setMachines(machinedb.push(...data.Items))
  //           console.log(machinedb)
  //           console.log(typeof(machinedb))
  //       }
  //   })
  
  //   const itemRows = [];
  //   for (let item of machinedb) {
  //     const row = { Client: cleintdb[machinedb[item].clientID].clientName,
  //                   Site: sitedb[machinedb[item].siteID].siteName,
  //                   Machine: machinedb[item].machineName};
  //     itemRows.push(row);
  //   }
  //   setMachinesdisplaydb(machinedisplaydb.push(itemRows))
  //   console.log(machinedisplaydb)
  //   console.log("I am in hooker")

  //   setFetchedtrue(true);
  //   console.log(fetchtrue)
  
    
  // }
  useEffect(() => {
    var params_client = {
      TableName: 'Client'
  }
  console.log("I am in")
  docClient.scan(params_client, function (err, data) {
      if (!err) {
          // const machinetable = {
          //       ...data.Items
          // };
          setClients(clientdb.push(...data.Items))
          console.log(clientdb)
          var params_site = {
            TableName: 'Site'
            }
            console.log("I am in")
            docClient.scan(params_site, function (err, data) {
                if (!err) {
                  // eslint-disable-next-line
                    // const machinetable = {
                    //       ...data.Items
                    // };
                    setSites(sitedb.push(...data.Items))
                    console.log(sitedb)
                    var params = {
                      TableName: 'Machine'
                  }
                  console.log("I am in")
                  docClient.scan(params, function (err, data) {
                      if (!err) {
                         // eslint-disable-next-line
                          // const machinetable = {
                          //       ...data.Items
                          // };
                          setMachines(machinedb.push(...data.Items))
                          console.log(machinedb)
                          console.log(typeof(machinedb))
                          const itemRows = [];
                          for (let i = 0; i < machinedb.length; i++) {
                            const siteA = sitedb.find(obj => {
                              return Number(obj.siteID) === Number(machinedb[i].siteID);
                            });
                            const clientA = clientdb.find(obj => {
                                  return Number(obj.clientID) === Number(machinedb[i].clientID);
                                });  
                            const row = {  Client:clientA.clientName,
                                           Site:siteA.siteName,
                                           Machine:machinedb[i].machineName};
                            machinedisplaydb.push(row);
                          }
                          
                          console.log(machinedisplaydb)
                          console.log(typeof(machinedisplaydb))
                          console.log(fetchtrue)
                          
                         
                          setFetchedtrue(true);
                          console.log(fetchtrue)
                          let itemList = machinedisplaydb.forEach(element => {
                            return(
                              <tr>
                                <td>{element.Client}</td>
                                <td>{element.Site}</td>
                                <td>{element.Machine}</td>
                              </tr>
                            )
                          
                          });

                      }
                  })
                }
            })
      }
  })
  

  
  
}, []);
    
    
  // useEffect(() => {
  // fetch("http://localhost:3000/DB.xlsx")
  //     .then((response) => {

  //       return response.blob();
  //     })
  //     .then((data) => {
  //       const promise = new Promise((resolve, reject) => {
  //         const fileReader = new FileReader();
  //         fileReader.readAsArrayBuffer(data);
    
  //         fileReader.onload = (e) => {
  //           const bufferArray = e.target.result;
    
  //           const wb = XLSX.read(bufferArray, { type: "buffer" });
    
  //           const wsname = wb.SheetNames[0];
    
  //           const ws = wb.Sheets[wsname];
    
  //           const data = XLSX.utils.sheet_to_json(ws);
    
  //           resolve(data);
  //         };
    
  //         fileReader.onerror = (error) => {
  //           reject(error);
  //         };
  //       });
    
  //       promise.then((d) => {
  //         setItems(d);
  //         console.log(d);
  //       });
        
  //       });
  //     }, []);

  return (
    <div>
      <br></br>
      <h1>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;Machine Details</h1>
      <input type="text" placeholder='Search...' onChange={(e)=>setQuary(e.target.value.toLowerCase())} className={classes.search}></input>
      <div style={{display: fetchtrue ? 'block' : 'none' }}>
      <table class="table container" >
        <thead>
          <tr>
            <th scope="col">Client</th>
            <th scope="col">Site</th>
            <th scope="col">Machine</th>
          </tr>
        </thead>
        <tbody>
          {machinedisplaydb.sort((a, b) => a.Client.localeCompare(b.Client)).filter((d)=> d.Client.toLowerCase().includes(quary)||(d.Site ? d.Site.toString() : '').toLowerCase().includes(quary)||(d.Machine ? d.Machine.toString() : '').toLowerCase().includes(quary)).map((d,index) => (
          // {machinedisplaydb.map((value,index) => (
            <tr key={index}>
              <td>{d.Client}</td>
              <td>{d.Site}</td>
              <td>{d.Machine}</td>
            </tr>
           ))} 
           {/* {itemList} */}
        </tbody>
      </table>
      </div>
    </div>
    
  );
}

export default MachineDetails;
