import classes from './ActionItem.module.css';
import React, { useState,useEffect} from "react";
import {useRef} from 'react';

function EditItem(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserid] = useState([]);
  const [loadingUser, setIsLoadingUser] = useState([]);
  const refuser = useRef(null);
  useEffect(() => {
    setIsLoading(true);
    fetch("https://flow-token-generator-app.azurewebsites.net/api/token", {
      method: "POST",
      headers: {
        "x-api-key":
          "YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY",
        "content-type": "application/json",
      },
      body: '{"teamName":"schenck-process"}',
    })
      .then((response) => {
        console.log("response.status: ", response.status);
        return response.text();
      })
      .then((data) => {
        let token = data; 
          fetch("https://accounts-schenck.flow.librestream.com/api/team/user", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const allusers = [];
            for (const name in data) {
              const user = {
                ...data[name],
              };
              // console.log(user);
              allusers.push(user);
            }
            setIsLoading(false);
            setIsLoadingUser(allusers);
            console.log(allusers);
          });
      });
        }, []);
  function userId(event) {
    console.log(event.target.value);
    setUserid(event.target.value);
  }
  function AddJob(){
    console.log(props.id);
    console.log(loadingUser);
    console.log(userid);
    const clientselected = loadingUser.find(obj => {
      return obj.emailAddress === userid;
    });
    console.log(clientselected);
    const name = clientselected.userId;
    console.log(name);
    fetch('https://flow-token-generator-app.azurewebsites.net/api/token',
      {method: 'POST', headers: {'x-api-key': 'YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY',
        'content-type': 'application/json',
        }, body:'{"teamName":"schenck-process"}'}
      ).then(response =>{
      console.log('response.status: ', response.status);
      return response.text();
    }).then(data=>{
      console.log(data);
      let token = data; 
      console.log(token);
    

    fetch('https://gateway-schenck.flow.librestream.com/scheduledjobs/v1/'+props.id+'/assignedUsers',{ method: "PUT",headers: {
        'Authorization': 'Bearer '+data,'content-type': 'application/json',
        }, body:'{"assignedUserIds":["'+name+'"]}'}
      ).then(response =>{
      console.log('response.status: ', response.status);
      if(response.status===200 || response.status===204){alert('The job is assigned to '+ userid+', please refresh the page to see the updated job list')}
      // return response.json();
    })

    });
    
  }
  
    return (
   
        <tr>
          <td><p>{props.name}</p></td>
          <td><p>{props.created}</p></td>
          <td><p>{props.state}</p></td>
          <td><p>{props.description}</p></td>
          <td><p>{props.dueByDate}</p></td>
          <td><p>{props.executioner}</p></td>
          <td>
          <input ref={refuser} name="user" className={classes.select} onChange={userId} list="data1"/>
          <datalist id="data1">
          <option value="" className={classes.unclickable}> &nbsp; User</option>
          {loadingUser.map((user)=> (
            <option value={user.emailAddress}>{user.emailAddress}</option>
            ))}
          </datalist>
          </td>
          <td><div className={classes.actions}><button onClick={AddJob}>Update</button></div></td>
        </tr>
     
    );
}
export default EditItem