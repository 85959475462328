import { useState , useEffect} from "react";
import ActionList from "../components/action/ActionList";


function DownloadReport() {
  const[isLoading, setIsLoading] = useState(true);
  const[loadingData, setIsLoadingData] = useState([]);
  //const[scheduledjob, setScheduleJob] = useState([]);
  useEffect(()=>{
    setIsLoading(true)
    fetch('https://flow-token-generator-app.azurewebsites.net/api/token',
    {method: 'POST', headers: {'x-api-key': 'YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY',
      'content-type': 'application/json',
      }, body:'{"teamName":"schenck-process"}'}
    ).then(response =>{
    console.log('response.status: ', response.status);
    return response.text();
  }).then(data=>{ 
    let token = data;
    fetch('https://gateway-schenck.flow.librestream.com/scheduledjobs/v1',{headers: {
      'Authorization': 'Bearer '+token,
      },}
    ).then(response =>{
    return response.json();
  }).then(data=>{
    // const allscheduledjobs = [];
    // //const allscheduledjob = [];
    // for (const jobId in data) {
    //   let scheduledjob = {
    //     ...data[jobId]
    //   };
    //   //console.log(scheduledjob.assignedUserIds[0]);
      
    // //   fetch('https://accounts.flow.librestream.com/api/team/user/'+scheduledjob.assignedUserIds[0],{headers: {
    // //   'Authorization': 'Bearer '+token,
    // //   },}
    // // ).then(response=>{
    // //   return response.json();
    // // }).then(data=>{
      
    //   //const allUserNames = [];
    //   // for (const assignedUserIds in data) {
    //   //   const userName = {
    //   //     ...data[assignedUserIds]
    //   //   };
    //   //   //allscheduledjob.push(userName);
        
    //   // }
    const allscheduledjobs = [];

    for (const jobId in data) {
      console.log(data[jobId].assignedUserIds[0]);
      fetch('https://accounts-schenck.flow.librestream.com/api/team/user/'+data[jobId].assignedUserIds[0],{headers: {
          'Authorization': 'Bearer '+token,
           },}).then(response =>{

        return response.json();

      }).then(result=>{

        //let token = result;

        console.log(result);

        

        // allscheduledjobs.push(scheduledjob);

        const scheduledjob = {

          ...data[jobId],result

        };

        console.log(scheduledjob);

        allscheduledjobs.push(scheduledjob);

        // setIsLoading(false);

        setIsLoadingData(allscheduledjobs);

        console.log(allscheduledjobs);

        if(allscheduledjobs.length === data.length){

          setIsLoading(false);

        }

      });

     

    

      
    }
    
    
    

  });

  });

  },[]);
  
  if(isLoading){
    return(
      <section>
        <p>
          Loading...
        </p>
      </section>
    );
  }

  return <section>
    <br></br>
    <h1>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;Download Report</h1>
    <ActionList items ={loadingData} />
    </section>;
}

export default DownloadReport;
