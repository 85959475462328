
import classes from './MainNavigation.module.css';

function Footer() {
  return (
    <div className={classes.footer}><h4 className={classes.footertext}>Schenck Process - CONiQ Assist Hub</h4></div>
    
    
  );
}

export default Footer;