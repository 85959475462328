import classes from './ActionItem.module.css';
import React from "react";

function ExecutedActionItem(props) {
  function DownloadJob() {
    console.log(props);
    console.log(props.id);
    console.log(props.jobId);
    fetch('https://flow-token-generator-app.azurewebsites.net/api/token',
      {method: 'POST', headers: {'x-api-key': 'YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY',
        'content-type': 'application/json',
        }, body:'{"teamName":"schenck-process"}'}
      ).then(response =>{
      console.log('response.status: ', response.status);
      return response.text();
    }).then(data=>{
      console.log(data);
      let token = data; 
      console.log(token);
    

    fetch('https://gateway-schenck.flow.librestream.com/jobs/v1/bson/'+props.jobId,{ method: "GET",headers: {
        'Authorization': 'Bearer '+data,
        },}
      ).then(response =>{
      console.log('response.status: ', response.status);
      if(response.status!==200){alert('The job is not comepleted.')}
      return response.json();
    }).then(data=>{
      console.log(props.clientJobId);
      let clientjobid= props.clientJobId;
      fetch('https://gateway-schenck.flow.librestream.com/reports/v1/generate/'+clientjobid+'?getUrlOnly=true',{ method: "GET",headers: {
      'Authorization': 'Bearer '+token,
      },}
    ).then(response =>{
    console.log('response.status: ', response.status);
    if(response.status===404){alert('There is an issue on downloading report. ClientJobId: '+clientjobid)}
    return response.text();
  }).then(data=>{
    // const allscheduledjobs = [];
    // for (const jobId in data) {
    //   const scheduledjob = {
    //     ...data[jobId]
    //   };
    //   console.log(scheduledjob);
    //   allscheduledjobs.push(scheduledjob);
    // }
    
    
    console.log(data);
    const link = document.createElement('a');
    link.href = data;
    link.download=props.name+`.pdf`
    // link.setAttribute(
    //   'download',
    //   props.name+`.pdf`,
    // );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);


    });
      

    });

    });
    
  }
    return (
   
        <tr>
          <td><p>{props.name}</p></td>
          <td><p>{props.created}</p></td>
          <td><p>{props.state}</p></td>
          <td><p>{props.executioner}</p></td>
          <td><div className={classes.actions}><button onClick={DownloadJob}>Download</button></div></td>
        </tr>
     
    );
}
export default ExecutedActionItem