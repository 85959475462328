
import classes from './ActionList.module.css';
import React, { useState,useEffect} from "react";
import * as XLSX from "xlsx";
import Multiselect from "multiselect-react-dropdown";
import {useRef} from 'react';
import * as AWS from 'aws-sdk'

function ScheduleJobsList(props){
  const multiselectRef = React.createRef();
  const refdate = useRef(null);
  const refworkflow = useRef(null);
  const refsite = useRef(null);
  const refclient = useRef(null);
  const reforderno = useRef(null);
  const refuser = useRef(null);
  const [items, setItems] = useState([]);
  const [scheduledtrue, setScheduledtrue] = useState(false);
  const [scheduleditems, setScheduleditems] = useState([]);
  const [client, setClient] = useState([]);
  const [site, setSite] = useState([]);
  const [clientname, setClientname] = useState(null);
  const [sitename, setSitename] = useState(null);
  const [machine, setMachine] = useState([]);
  const [sitedb, setSitedb] = useState([]);
  const [machinedb, setMachinedb] = useState([]);
  const [data, setData] = useState([]);
  const [body, setDatabody] = useState([]);
  const [ordernumber, setOrdernumber] = useState([]);
  const [workflowid, setWorkflowid] = useState([]);
  const [userid, setUserid] = useState([]);
  const [datetime, setDate] = useState([]);
  const docClient = new AWS.DynamoDB.DocumentClient()
  

  // useEffect(() => {
    
  // fetch("http://localhost:3000/DB.xlsx")
  //     .then((response) => {

  //       return response.blob();
  //     })
  //     .then((data) => {
  //       const promise = new Promise((resolve, reject) => {
  //         const fileReader = new FileReader();
  //         fileReader.readAsArrayBuffer(data);
    
  //         fileReader.onload = (e) => {
  //           const bufferArray = e.target.result;
    
  //           const wb = XLSX.read(bufferArray, { type: "buffer" });
    
  //           const wsname = wb.SheetNames[0];
    
  //           const ws = wb.Sheets[wsname];
    
  //           const data = XLSX.utils.sheet_to_json(ws);
    
  //           resolve(data);
  //         };
    
  //         fileReader.onerror = (error) => {
  //           reject(error);
  //         };
  //       });
    
  //       promise.then((d) => {
  //         setItems(d);
  //         console.log(d);
  //         const unique = [...new Set(d.map(s => s.Client))];
  //         setClient(unique)
  //         console.log(unique);
  //       });
         
  //       });
  //     }, []);
  useEffect(() => {
    var params_client = {
      TableName: 'Client'
  }
  console.log("I am in")
  console.log(ordernumber)
  docClient.scan(params_client, function (err, data) {
      if (!err) {
          // const machinetable = {
          //       ...data.Items
          // };
          client.push(...data.Items)
          console.log(client)
          var params_site = {
            TableName: 'Site'
            }
            console.log("I am in")
            docClient.scan(params_site, function (err, data) {
                if (!err) {
                  // eslint-disable-next-line
                    // const machinetable = {
                    //       ...data.Items
                    // };
                    site.push(...data.Items)
                    sitedb.push(...data.Items)
                    console.log(site)
                    var params = {
                      TableName: 'Machine'
                  }
                  console.log("I am in")
                  docClient.scan(params, function (err, data) {
                      if (!err) {
                         // eslint-disable-next-line
                          // const machinetable = {
                          //       ...data.Items
                          // };
                          machine.push(...data.Items)
                          machinedb.push(...data.Items)
                          console.log(machine)
                          // console.log(typeof(machine))
                          // const itemRows = [];
                          // for (let i = 0; i < machine.length; i++) {
                          //   const siteA = site.find(obj => {
                          //     return Number(obj.siteID) === Number(machine[i].siteID);
                          //   });
                          //   const clientA = client.find(obj => {
                          //         return Number(obj.clientID) === Number(machine[i].clientID);
                          //       });  
                          //   const row = {  Client:clientA.clientName,
                          //                  Site:siteA.siteName,
                          //                  Machine:machine[i].machineName};
                          //   machinedisplaydb.push(row);
                          // }
                          
                          // console.log(machinedisplaydb)
                          // console.log(typeof(machinedisplaydb))
                          // console.log(fetchtrue)
                         
                          // setFetchedtrue(true);

                      }
                  })
                }
            })
      }
  })
 
}, []);
      function handleClient(event) {
        console.log(event.target.value);
        // setMachine(machinedb);
        // setSite(sitedb);
        // console.log(sitedb);
        // console.log(site);
        const clientselected = client.find(obj => {
          return obj.clientName === event.target.value;
        });
        console.log(clientselected);
        const name = clientselected.clientID;
        console.log(name);
        const filtered = sitedb.filter(site => {
          return site.clientID === name;
        });
        const unique = [...filtered];
        setSite(unique);
        // const model = [];
        // setMachine(model);
        setClientname(event.target.value);
        console.log(site);

      }
      function handleSite(event) {
        console.log(event.target.value);
        const siteselected = site.find(obj => {
          return obj.siteName === event.target.value;
        });
        console.log(siteselected)
        const name =siteselected.siteID;
        const filtered = machinedb.filter(machine => {
          return machine.siteID === name;
        });
        const unique = [...filtered];
        console.log(filtered)
        setMachine(unique);
        setSitename(event.target.value);
      }
      function orderNumber(event) {
        console.log(event.target.value);
        setOrdernumber(event.target.value);
      }
      function workflowId(event) {
        console.log(event.target.value);
        setWorkflowid(event.target.value);
      }
      function userId(event) {
        console.log(event.target.value);
        setUserid(event.target.value);
      }
      function dateInfo(event) {
        console.log(event.target.value);
        setDate(event.target.value);
      }
      function addjob() {
        refclient.current.value = [];
        refsite.current.value = [];
        setMachine(machinedb);
        setSite(sitedb);
        setClientname(null);
        setSitename(null);
        multiselectRef.current.resetSelectedValues();
        refdate.current.value = null;
        refworkflow.current.value = [];
        reforderno.current.value = null;
        refuser.current.value = [];
        console.log("print");
        console.log(data);
        console.log(ordernumber);
        console.log(workflowid);
        console.log(userid);
        console.log(datetime);
        const workflowidb= props.items.filter(items => {
          return items.name === workflowid;
        });
        const useridb= props.users.filter(users => {
          return users.username === userid;
        });
        // console.log(props.items);
        // console.log(props.users);
        console.log(workflowidb[0].workflowId);
        console.log(useridb[0].userId);
        var arr1 = ['name', 'username', 'workflowid', 'workflowname', 'userid', 'duedate'];
        for(let i = 0; i < data.length; i++) {
          var obj = {};
          // console.log(jobname);
          const name = data[i]+"_"+ordernumber+"_"+sitename+"_"+clientname;
          obj[arr1[0]] = name;
          obj[arr1[1]] = userid;
          obj[arr1[2]] = workflowidb[0].workflowId;
          obj[arr1[3]] = workflowid;
          obj[arr1[4]] = useridb[0].userId;
          obj[arr1[5]] = datetime;
          body.push(obj);
        }
        console.log(body);
      }
      function schedulejobs() {
        
        fetch("https://flow-token-generator-app.azurewebsites.net/api/token", {
          method: "POST",
          headers: {
            "x-api-key":
              "YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY",
            "content-type": "application/json",
          },
          body: '{"teamName":"schenck-process"}',
        })
          .then((response) => {
            console.log("response.status: ", response.status);
            return response.text();
          })
          .then((data) => {
            // console.log(data);
            let token = data; 
            let bodyerror=[];
            
            for(let i = 0; i < body.length; i++) {
              var arr2 = ['assignedUserIds','description','priority','name','dueByDate','tasks'];
              var samplebody =[];
              var arr4 =[];
              var obj0={};
              obj0[arr2[0]] = [body[i].userid];
              obj0[arr2[1]] = body[i].name;
              obj0[arr2[2]] = false;
              obj0[arr2[3]] = body[i].name;
              obj0[arr2[4]] = body[i].duedate;
              var arr3=['artifactId','taskType'];
              var obj1={};
              obj1[arr3[0]] = body[i].workflowid;
              obj1[arr3[1]] = "workflow";
              arr4.push(obj1);
              obj0[arr2[5]] = arr4;
              samplebody.push(obj0);
              var bodypost = JSON.stringify(samplebody[0]);
              // console.log(bodypost);
              // console.log(scheduleditems);
              // console.log(body);
              
            

              fetch("https://gateway-schenck.flow.librestream.com/scheduledjobs/v1", {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + token,
                  'Content-Type': 'application/json',
                },
                body:bodypost ,
              })
                .then((response) => {
                  if((response.status === 200) || (response.status ===201)){scheduleditems.push(body[i]);console.log(scheduleditems);}
                  else {bodyerror.push(body[i]);alert(body[i].name+': '+response.status);}
                  return bodyerror;
                });
            }
            setTimeout(() => {  console.log(bodyerror);setDatabody(bodyerror);setScheduledtrue(true); }, 2000);

          });
          
      }
      const handleRemoveItem = (e) => {
        const name = e.target.getAttribute("name")
        setDatabody(body.filter(d => d.name !== name));
       };
    return (
    <ul className={classes.list}>
         <li className={classes.item}>
        
        <div className={classes.content}>
        <table class="table container">
        <tr>
          <td > 
            <select name="workflow" className={classes.select} onChange={handleClient} ref={refclient}>
            <option value="" className={classes.unclickable}>&nbsp; Client</option>
            {client.sort((a, b) => a.clientName.localeCompare(b.clientName)).map((d) => (
              <option >{d.clientName}</option>
              ))}
            </select>
          </td>
          <td >
            <div style={{display: clientname ? 'block' : 'none'}}>
            <select name="workflow" className={classes.select} onChange={handleSite}  ref={refsite}>
            <option value="" className={classes.unclickable}>&nbsp; Site</option>
            {site.sort((a, b) => a.siteName.localeCompare(b.siteName)).map((d) => (
              <option >{d.siteName}</option>
              ))}
            </select>
            </div>
          </td>
          <td>
            <div style={{display: sitename ? 'block' : 'none'}}>
            <Multiselect ref={multiselectRef}
            
              isObject={false}
              onRemove={(event) => {
                console.log(event);setData(event);console.log(data);
              }}
              onSelect={(event) => {
                console.log(event);setData(event);console.log(data);
              }}
              
              onChange={setData}
              options={machine.sort((a, b) => a.machineName.localeCompare(b.machineName)).map((d) => (d.machineName))}
              showCheckbox
              
            ></Multiselect></div>
          </td>
        </tr>
        <tr>
          <td>
          <input  ref={reforderno} name="orderno"  placeholder='Service Order Number' className={classes.select} onChange={orderNumber} />
          </td>
          <td>
          
          <input ref={refworkflow} name="workflow" className={classes.select} onChange={workflowId} list="data"/>
          <datalist id="data">
          {/* <option value="" className={classes.unclickable}>&nbsp; <font>Workflow</font></option> */}
            {props.items.map((item)=> (
            <option value={item.name}>{item.name}</option>
            ))}
            </datalist>
          </td>
          <td>
          <input ref={refuser} name="user" className={classes.select} onChange={userId} list="data1"/>
          <datalist id="data1">
          <option value="" className={classes.unclickable}> &nbsp; User</option>
          {props.users.map((user)=> (
            <option value={user.emailAddress}>{user.emailAddress}</option>
            ))}
          </datalist>
          </td>
        </tr>
        <tr>
          <td>
          <input ref={refdate} name="date"  type="date" className={classes.select} onChange={dateInfo}/>
          </td>
          <td><button onClick={addjob} className={classes.button}>Add Job</button></td>
          <td></td>
        </tr>
         </table>
        </div>
        <div className={classes.content}>
        <table class="table container">
          <tr>
            <td><h4>Job Name</h4></td>
            <td><h4>Workflow</h4></td>
            <td><h4>User</h4></td>
            <td><h4>Date Scheduled</h4></td>
            <td><h4>Remove</h4></td>
          </tr>
          {body.map((d)=> (
          <tr>
            <td>{d.name}</td>
            <td>{d.workflowname}</td>
            <td>{d.username}</td>
            <td>{d.duedate}</td>
            <td><span name={d.name} onClick={handleRemoveItem} className={classes.del}>
              x
            </span></td>
          </tr>
))}
        </table>
        <button onClick={schedulejobs} className={classes.button}>Scheduled Jobs</button>
        </div>
    <div style={{display: scheduledtrue ? 'block' : 'none' }}>
        <div className={classes.contentpass} >
          <label><h4>Scheduled Job List</h4></label>
        <table class="table container">
          <tr>
            <td><h4>Job Name</h4></td>
            <td><h4>Workflow</h4></td>
            <td><h4>User</h4></td>
            <td><h4>Date Scheduled</h4></td>
          </tr>
          {scheduleditems.map((d)=> (
          <tr>
            <td>{d.name}</td>
            <td>{d.workflowname}</td>
            <td>{d.username}</td>
            <td>{d.duedate}</td>
          </tr>
        ))}
         </table>
        </div>
        </div>
    </li>
    </ul>
    
    );

}

export default ScheduleJobsList