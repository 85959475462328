import { useState, useEffect } from "react";
import ScheduleJobsList from "../components/action/ScheduleJobsList";

function ScheduleJobs() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingData, setIsLoadingData] = useState([]);
  const [loadingUser, setIsLoadingUser] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch("https://flow-token-generator-app.azurewebsites.net/api/token", {
      method: "POST",
      headers: {
        "x-api-key":
          "YkHqCYlbpenM565D6McXkw.BFf-4CrAR8FYqfByLW2k_-PceQGrKMS24XIDHK8pPQY",
        "content-type": "application/json",
      },
      body: '{"teamName":"schenck-process"}',
    })
      .then((response) => {
        console.log("response.status: ", response.status);
        return response.text();
      })
      .then((data) => {
        let token = data; 
        fetch("https://gateway-schenck.flow.librestream.com/workflows/v2", {
          headers: {
            Authorization: "Bearer " + data,
          },
        })
          .then((response) => {
            if(response.status===500){window.location.reload(false);}
            return response.json();
          })
          .then((data) => {
            const allworkflows = [];
            for (const name in data) {
              console.log(data[name].isArchived);
              if(data[name].isArchived===false && data[name].name.includes('APAC-PAU')){
                const workflow = {
                  ...data[name],
                };
                allworkflows.push(workflow);
              }
              // console.log(workflow);
            }
            setIsLoading(false);
            setIsLoadingData(allworkflows);
            console.log(allworkflows);
            fetch("https://accounts-schenck.flow.librestream.com/api/team/user", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const allusers = [];
            for (const name in data) {
              const user = {
                ...data[name],
              };
              // console.log(user);
              allusers.push(user);
            }
            setIsLoading(false);
            setIsLoadingUser(allusers);
            // console.log(allusers);
          });
        });
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  return (
    <section>
      <br></br>
      <h1>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;Schedule Jobs</h1>
      <ScheduleJobsList items={loadingData} users={loadingUser}/>
    </section>
  );
}

export default ScheduleJobs;
