import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import * as AWS from 'aws-sdk';
import { Amplify } from 'aws-amplify';
import config from './config.json';
// import { ConfigurationOptions } from 'aws-sdk'
// eslint-disable-next-line
Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'arn:aws:cognito-idp:ap-southeast-2:755239865696:userpool/ap-southeast-2_mHhrqKJYT',

    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-southeast-2_mHhrqKJYT',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '3elcqdemmfvbgs0pjv45ke6rot',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
}
});
AWS.config.update( {
  region: 'ap-southeast-2',
  secretAccessKey: 'NNOWeSeJtP0TeXWIjHxFEfX4yZO253bSaeRIahSG',
  accessKeyId: 'AKIA27V6NZVQBZM7UDN7'
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
