
import classes from './MainNavigation.module.css';

function MainDivider() {
  return (
    <div className={classes.divider}></div>
    
    
  );
}

export default MainDivider;